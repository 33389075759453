import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';
 import "./Dropdown.css"

export const  Dropdown = () =>{


        return(
            <div >           
            <ul className="list1">
                <li><a  className="item" href="https://gst.gov.in/" >GST Website</a>  </li>                 
                <li><a  className="item" href="https://youtube.vipca.co.in/" >Youtube Channel</a>  </li>
                <li><a  className="item" href="https://fb.vipca.co.in/" >Facebook</a>  </li>       
                <li><a  className="item" href="https://vipca.co.in/privacy-policy" >Privacy Policy</a>  </li>
                <li><a  className="item" href="https://vipca.co.in/terms-of-service/" >Terms of Service</a>  </li>
                <li><a  className="item" href="https://vipca.co.in/e-pay-tnc" >E-Payments Terms and Conditions</a>  </li>
                <li><a  className="item" href="https://vipca.co.in/cancellation-policy" >Cancellation Policy</a>  </li>
                <li><a  className="item" href="https://vipca.co.in/refund-policy/" >Refund Policy</a>  </li>
                <li><a  className="item" href="https://developer.vipca.co.in/" >Developer Credits</a>  </li>
             
        
            </ul>
            </div>


    
        );

}
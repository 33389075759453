import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Router } from 'react-router';
import SimpleReactLightbox from 'simple-react-lightbox';
import { MemoryRouter } from 'react-router'
import { BrowserRouter } from 'react-router-dom';

const baseURl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


ReactDOM.render(<BrowserRouter basename={baseURl}>
  <SimpleReactLightbox>
  
   <App />
 
   </SimpleReactLightbox>
</BrowserRouter>
 ,
  rootElement
);

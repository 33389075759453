import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';
 import "./Dropdown.css"

export const  DropdownAbout = () =>{


        return(
            <div >           
            <ul className="list">
                <li><a  className="item" href="https://vipca.co.in/about" >Meet Us</a>  </li>                  
                <li><a  className="item" href="https://secretariat.vipca.co.in/" >Secretariat</a>  </li>       
                <li><a  className="item" href="https://subcommittee.vipca.co.in/" >Sub-Committee</a>  </li>               
                <li><a  className="item" href="https://gallery.vipca.co.in/" >Gallery</a>  </li>
                    
            </ul>
            </div>


    
        );

}
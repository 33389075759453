import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';
 import "./Dropdown.css"

export const  DropdownServices = () =>{


        return(
            <div >           
            <ul className="list">
                <li><a  className="item" href="https://vipca.co.in/services/" >Know Us</a>  </li>                 
                <li><a  className="item" href="https://enewsletter.vipca.co.in/" style={{color:"whitesmoke"}} >E-Newsletter</a>  </li>
                <li><a  className="item" href="https://newsletter.vipca.co.in/" >Newsletter</a>  </li>       
                <li><a  className="item" href="https://events.vipca.co.in/" >Events</a>  </li>
                <li><a  className="item" href="https://portal.vipca.co.in/" >Member Portal</a>  </li>    
            </ul>
            </div>


    
        );

}
import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "./firebase";
import'./Newsletter.css';
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { motion } from 'framer-motion';

import { LoginPageNavbar } from "./LoginPageNavbar";

const useStyles = makeStyles({
  root: {
    
    maxWidth:'auto'
  },
});


export default function Newsletter() {
  const classes = useStyles();
  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState([false]);
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([
    "All Months",
    "January",
    "February",
     "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ])
   const [documentYear, setDocumentYear] = useState("")
   const [documentMonth, setDocumentMonth] = useState("")
   
  
   function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }

   useEffect(()=>{

    getyears()
    setDocumentMonth("All Months")
  },[])
  
     function getyears(){
       const year = (new Date()).getFullYear()
       let items=[]
        setDocumentYear(year)
       for(let i=year; i>=2016; i--)
          items.push(i)
        
        setYears(items)
  
       
     }

  const ref = firebase.firestore().collection("demonewsletter").orderBy('monthid','desc')
  
  function getSchools(){

    setloading(true);
    const items=[];
    ref.onSnapshot((querySnapshot)=>{
    
      querySnapshot.forEach((doc)=>{
        items.push(doc.data())
      });
      setschools(items);
      setloading(false);
    })
  }  
  
  useEffect(()=>{
    getSchools();
 
  },[]);

 
const history = useHistory()
  return (
     <>
        
      <LoginPageNavbar/>
   
    <div style={{margin:"20px"}}>

<div style={{ paddingTop: "120px" }}></div>

    
          <div className="d-flex justify-content-center" >
              
        <div className="d-flex p-2 col-example">
          <h1 style={{ fontWeight:"600", fontFamily:"Trebuchet MS"}}>E-Newsletter </h1>
        </div>
      </div>



          <MDBContainer  
    >
      <MDBRow>  <MDBCol md="3" >
      </MDBCol>
      
      <MDBCol md="3">  <label style={{ fontSize: "30px" }}> Select Month <span style={{color:"rgb(207,148,80)"}}>*</span> </label>
          <select value={documentMonth}  style = {{ width: "80%" }} className="form-control" onChange={(e)=> setDocumentMonth(e.target.value)}>
          {months.map((val,key)=>(
            <option key={key} value={val}>{val}</option>
          ))}
          </select>
      
      </MDBCol>
      <MDBCol md='3'><label style={{ fontSize: "30px" }}> Select Year <span style={{color:"rgb(207,148,80)"}}>*</span> </label>
          <select value={documentYear}  style = {{ width: "80%" }} className="form-control" onChange={(e)=> setDocumentYear(e.target.value)}>
          {years.map((val,key)=>(
            <option key={key} value={val}>{val}</option>
          ))}
          </select></MDBCol> 
      <MDBCol md='3'></MDBCol>
      
      </MDBRow>
     
    </MDBContainer>

            <div style={{paddingTop:"80px"}}></div>

     <div className="img-grid">
         {schools.filter((val)=>{


           if(val.month ==documentMonth.toLowerCase()  && val.year== documentYear){return val}
           if(documentMonth=="All Months"){if(val.year==documentYear){  return val}}
         
      }).map((doc,key)=>(<>          
     
    <motion.div className="img-wrap" key={key} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={()=> window.open(doc.newsletter, "_blank")}
        >
    
        
          <motion.img src={doc.image} alt="uploaded pic"
          
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
           
        </motion.div>
        

    </>))}
    </div>
    </div>
    </>
  );
}

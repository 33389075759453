import { render } from '@testing-library/react';
import React,{useState, useEffect} from 'react';
import { Dropdown } from './Dropdown';
import { DropdownAbout } from './DropdownAbout';

import { DropdownServices } from './DropdownServices';
import "./LoginPageNavbar.css"

export const  LoginPageNavbar = () =>{

        const [clicked, setClicked] = useState(false);
        const [show, setShow] = useState(false)
        const [showAbout, setShowAbout] = useState(false)
        const [showServices, setShowServices] = useState(false)

        const onMouseEnterAbout = () => {
           
              setShowAbout(true);
            
          };
        
          const onMouseLeaveAbout = () => {
           
              setShowAbout(false);
            
          };

        const onMouseEnter = () => {
           
              setShow(true);
            
          };
        
          const onMouseLeave = () => {
           
              setShow(false);
            
          };

        const onMouseEnterServices = () => {
           
              setShowServices(true);
            
          };
        
          const onMouseLeaveServices = () => {
           
              setShowServices(false);
            
          };


        return(<>
            <nav className="NavbarItems2" ><div className="navbar-logo2"><img src={"https://firebasestorage.googleapis.com/v0/b/vip-ca.appspot.com/o/VIPCA_Logo_BGLess.png?alt=media&token=c8b18e32-e8c6-492b-98b6-fce6a60c3fae"}  className="f" width="100px" ></img>    </div> 
           
      
            <div className="menu-icon" onClick={()=>setClicked(!clicked)}>
                <i className={clicked? "fas fa-times":"fas fa-bars"}></i> </div>
            <ul className={clicked? "nav-menu2 active" : "nav-menu2"}>
                <li><a className="nav-links2"  href="https://vipca.co.in/" >HOME</a>  </li>                 
                <li  onMouseEnter={onMouseEnterAbout} onMouseLeave={onMouseLeaveAbout}><a className="nav-links2" href="#">ABOUT US</a> {showAbout? <DropdownAbout/>:""} </li>      
                <li onMouseEnter={onMouseEnterServices} onMouseLeave={onMouseLeaveServices}><a className="nav-links-portal2" href="#" >SERVICES</a> {showServices? <DropdownServices/>:""}  </li>                 
                <li><a className="nav-links2"  href="https://vipca.co.in/contact/" >CONTACT US</a>  </li>                  
                <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}><a className="nav-links2" href="#" >EXPLORE</a>  {show?<Dropdown/>:""}   </li>                 
                       
            </ul>
           
           
           
            </nav>
    </>
        );

}
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { BrowserRouter } from 'react-router-dom';
import './App.css'
import Newsletter from './Newsletter';
function App() {

  return (
    <BrowserRouter>
     
        <div>
          <Route exact path="/" component={Newsletter}/>
            
        </div>
      </BrowserRouter>
    
     
      
  
  );
}

export default App;